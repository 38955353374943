import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SelectOrderTypePageRoutingModule } from './select-order-type-routing.module';

import { SelectOrderTypePage } from './select-order-type.page';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from "../../../core/pipe.module"

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SelectOrderTypePageRoutingModule,
    TranslateModule,
    PipeModule
  ],
  declarations: [SelectOrderTypePage],
  exports: [SelectOrderTypePage]
})
export class SelectOrderTypePageModule { }
